import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'

export default {
  async getQuoteTemplateItems(templateId) {
    const url = `/quotetemplates/items/${templateId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getQuoteTemplates(filter) {
    const serialised = this.serialiseQuoteTemplateFilter(filter)
    const url = `/quotetemplates/getQuoteTemplates?filter=${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseQuoteTemplateFilter(filter) {
    const entityProto = require('../assets/proto/quotetemplate/QuoteTemplateFilter.proto')
    const filterMessage = entityProto.QuoteTemplateFilter.QuoteTemplateFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getQuoteTemplateByTemplateId(templateId) {
    const url = `/quotetemplates/getQuoteTemplate/${templateId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async updateQuoteTemplate(template) {
    const url = '/quotetemplates/updateQuoteTemplate'
    const result = await FqApi.post(url, template)
    return result.data
  },
  async insertQuoteTemplate(template) {
    const url = '/quotetemplates/insertQuoteTemplate'
    const result = await FqApi.post(url, template)
    return result.data
  },
  async deleteQuoteTemplate(template) {
    const url = '/quotetemplates/deleteQuoteTemplate'
    const result = await FqApi.post(url, template)
    return result.data
  },
  async getSortedQuoteTemplateItems(templateId) {
    const url = `/quotetemplates/getSortedQuoteTemplateItems?templateId=${templateId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async insertNewQuoteTemplateItems(templateId, newItem) {
    const url = `/quotetemplates/insertQuoteTemplateItems?templateId=${templateId}`
    const result = await FqApi.post(url, newItem)
    return result.data
  },
  async updateQuoteTemplateItem(item) {
    const url = '/quotetemplates/updateQuoteTemplateItem'
    const result = await FqApi.post(url, item)
    return result.data
  },
  async updateQuoteTemplateItems(items) {
    const url = '/quotetemplates/updateQuoteTemplateItems'
    const result = await FqApi.post(url, items)
    return result.data
  },
  async deleteQuoteTemplateItem(item) {
    const url = '/quotetemplates/deleteQuoteTemplateItem'
    const result = await FqApi.post(url, item)
    return result.data
  },
  async createNewTemplateWithItems(template, items) {
    const url = '/quotetemplates/createNewTemplateWithItems'
    const payload = {
      quoteTemplateModel: template,
      quoteTemplateItemModels: items
    }
    const result = await FqApi.post(url, payload)
    return result.data
},
async CheckTemplateNameValidity(templateName) {
  const url = `/quotetemplates/CheckTemplateNameValidity?templateName=${templateName}`
  const result = await FqApi.get(url)
  return result.data
}
}